/**
 * @generated SignedSource<<d24b88bd49ef6f8157a21381b2b31310>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ButtonAction = "BUTTON_ACTION_CLOSE" | "BUTTON_ACTION_LINK" | "BUTTON_ACTION_UNKNOWN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type FindActiveDismissibleModalFragment_query$data = {
  readonly activeDismissibleModal: {
    readonly description: string | null;
    readonly endTime: SerializedDateTime;
    readonly featureFlags: ReadonlyArray<string>;
    readonly id: string;
    readonly imageUrl: string | null;
    readonly modalId: string;
    readonly primaryButtonAction: ButtonAction;
    readonly primaryButtonText: string;
    readonly primaryButtonUrl: string | null;
    readonly secondaryButtonAction: ButtonAction | null;
    readonly secondaryButtonText: string | null;
    readonly secondaryButtonUrl: string | null;
    readonly startTime: SerializedDateTime;
    readonly title: string | null;
    readonly url: string;
  } | null;
  readonly " $fragmentType": "FindActiveDismissibleModalFragment_query";
};
export type FindActiveDismissibleModalFragment_query$key = {
  readonly " $data"?: FindActiveDismissibleModalFragment_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"FindActiveDismissibleModalFragment_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "companyId"
    },
    {
      "kind": "RootArgument",
      "name": "url"
    },
    {
      "kind": "RootArgument",
      "name": "userId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "FindActiveDismissibleModalFragment_query",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "companyId",
          "variableName": "companyId"
        },
        {
          "kind": "Variable",
          "name": "url",
          "variableName": "url"
        },
        {
          "kind": "Variable",
          "name": "userId",
          "variableName": "userId"
        }
      ],
      "concreteType": "DismissibleModal",
      "kind": "LinkedField",
      "name": "activeDismissibleModal",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "imageUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endTime",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startTime",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "primaryButtonAction",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "primaryButtonText",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "primaryButtonUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "secondaryButtonAction",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "secondaryButtonText",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "secondaryButtonUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "modalId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "featureFlags",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "cf6752e0a7d5dca66a21340ede77ba4e";

export default node;

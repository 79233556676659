import React, { useCallback, useEffect, useState } from 'react';

import { StandardDialog, Text } from '@attentive/picnic';
import { HomeModalViewed, Tracker, TrackerEvents } from '@attentive/acore-utils';

import { ModalButton } from './index';
import imageFilled from '../../assets/ImageFilled.png';

export interface ModalProps {
  modalId?: string;
  title: string | null;
  description: string | null;
  imageUrl: string | null;
  primaryButtonAction: string;
  primaryButtonText: string;
  primaryButtonUrl?: string;
  secondaryButtonAction?: string | null;
  secondaryButtonText?: string | null;
  secondaryButtonUrl?: string;
  handleClose: () => void;
  containerRef?: HTMLElement | null;
}

export const DismissibleModal: React.VFC<ModalProps> = ({
  modalId,
  title,
  description,
  imageUrl,
  primaryButtonAction,
  primaryButtonText,
  primaryButtonUrl,
  secondaryButtonAction,
  secondaryButtonText,
  secondaryButtonUrl,
  handleClose,
  containerRef,
}) => {
  const mediaType = imageUrl?.endsWith('.mp4') ? 'video' : 'image';
  const [isOpen, setIsOpen] = useState(true);

  const trackModalEvent = useCallback(
    (eventName: string) => {
      Tracker.track({
        eventName,
        properties: {
          modalId,
        },
      } as HomeModalViewed);
    },
    [modalId]
  );
  useEffect(() => {
    if (modalId) {
      trackModalEvent(TrackerEvents.HOME_MODAL_VIEWED);
    }
  }, [modalId, trackModalEvent]);

  const onClose = () => {
    if (modalId) {
      trackModalEvent(TrackerEvents.HOME_MODAL_DISMISSED);
    }
    handleClose();
    setIsOpen(false);
  };

  return (
    <StandardDialog
      includeOverlay={!containerRef}
      modal={!containerRef}
      open={containerRef ? true : isOpen}
      onOpenChange={onClose}
    >
      <StandardDialog.Content
        portalContainer={containerRef}
        css={{
          minWidth: 'min(85vw, 450px)',
          maxWidth: 600,
          zIndex: `${containerRef ? '1' : undefined}`,
        }}
      >
        {mediaType === 'image' ? (
          <StandardDialog.HeroImage src={imageUrl ? imageUrl : imageFilled} />
        ) : (
          <StandardDialog.HeroImage
            src={imageUrl ? imageUrl : imageFilled}
            as="video"
            controls
            autoPlay
            muted
            loop
          />
        )}
        <StandardDialog.Header>
          <StandardDialog.Heading>{title}</StandardDialog.Heading>
        </StandardDialog.Header>
        <StandardDialog.Body>
          <Text>{description}</Text>
        </StandardDialog.Body>
        <StandardDialog.Footer>
          {secondaryButtonAction && secondaryButtonText && (
            <ModalButton
              buttonAction={secondaryButtonAction}
              buttonText={secondaryButtonText}
              buttonUrl={secondaryButtonUrl}
              handleClose={onClose}
              variant="secondary"
            />
          )}
          <ModalButton
            buttonAction={primaryButtonAction}
            buttonText={primaryButtonText}
            buttonUrl={primaryButtonUrl}
            handleClose={onClose}
            variant="primary"
          />
        </StandardDialog.Footer>
      </StandardDialog.Content>
    </StandardDialog>
  );
};
